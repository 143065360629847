import React, {useContext, useEffect, useState} from 'react';
import './styles.css'
import appLogo from '../../../assets/images/longhorn-logo.png'
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import Marquee from "react-fast-marquee";

export default props => {

    const location = useLocation();
    console.log(location.pathname);
    const pathName = location.pathname
    const marqueeRef = React.createRef();
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const [yields, setYields] = useState("")

    function loadYields() {
        axios.get('https://data.mongodb-api.com/app/application-0-mbwce/endpoint/yields')
            .then(response => {
                console.log("result", response.data.result)
                setYields(response.data.result)
            })
            .catch(error => {
                console.log("error", error)
                setYields('Something went wrong. Please try refreshing the page.')
            });
    }

    useEffect(() => {
        loadYields();
    }, []);


    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div className="row">
                <div className="col-lg-3">
                    <div className="site-logo">
                        <Link to="/">
                            <img src={appLogo} alt="Site Logo"/>
                        </Link>

                    </div>
                    {/*Responsive Menu Start */}

                    <a href="#" aria-haspopup="true" role="button" tabIndex={0}
                       className="slicknav_btn slicknav_collapsed"
                       onClick={() => {
                           setIsNavExpanded(!isNavExpanded)
                       }}
                    >
                        <svg style={{width: 50, height: 50}} id="menuButton"
                             className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
                             focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MenuIcon">
                            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                        </svg>
                    </a>

                    <div className="finves-responsive-menu" style={{display: isNavExpanded ? "block" : "none"}}
                         onClick={() => {
                             setIsNavExpanded(!isNavExpanded)
                         }}
                    >
                        <div className="slicknav_menu">

                            <ul className="slicknav_nav slicknav_hidden" role="menu">
                                <li className="active">
                                    <Link role="menuitem" to="/"
                                          style={pathName === "/" ? {color: "red"} : {color: "black"}}>
                                        Home
                                    </Link>
                                </li>

                                <li>
                                    <Link role="menuitem" to="/about"
                                          style={pathName === "/about" ? {color: "red"} : {color: "black"}}>
                                        <span> About us</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link role="menuitem" to="/services"
                                          style={pathName === "/services" ? {color: "red"} : {color: "black"}}>
                                        Products and Services
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/careers"
                                          style={pathName === "/careers" ? {color: "red"} : {color: "black"}}>
                                        Careers
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/team"
                                          style={pathName === "/team" ? {color: "red"} : {color: "black"}}>
                                        <span>Our People</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/contact"
                                          style={pathName === "/contact" ? {color: "red"} : {color: "black"}}>
                                        contact
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/property"
                                          style={pathName === "/property" ? {color: "red"} : {color: "black"}}>
                                        Property
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/forms"
                                          style={pathName === "/forms" ? {color: "red"} : {color: "black"}}>
                                        forms
                                    </Link>
                                </li>


                                <li>
                                    <a role="menuitem" href="https://online.longhorn-associates.com/" target="_blank">
                                        login
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* Responsive Menu End */}
                </div>
                <div className="col-lg-7">
                    <div className="mainmenu">
                        <nav>
                            <ul id="navigation_menu">
                                <li className="active">
                                    <Link role="menuitem" to="/"
                                          style={pathName === "/" ? {color: "red"} : {color: "black"}}>
                                        Home
                                    </Link>
                                </li>

                                <li>
                                    <Link role="menuitem" to="/about"
                                          style={pathName === "/about" ? {color: "red"} : {color: "black"}}>
                                        <span> About us</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link role="menuitem" to="/services"
                                          style={pathName === "/services" ? {color: "red"} : {color: "black"}}>
                                        Products and Services
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/careers"
                                          style={pathName === "/careers" ? {color: "red"} : {color: "black"}}>
                                        Careers
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/team"
                                          style={pathName === "/team" ? {color: "red"} : {color: "black"}}>
                                        <span>Our People</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/contact"
                                          style={pathName === "/contact" ? {color: "red"} : {color: "black"}}>
                                        contact
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/property"
                                          style={pathName === "/property" ? {color: "red"} : {color: "black"}}>
                                        Property
                                    </Link>
                                </li>
                                <li>
                                    <Link role="menuitem" to="/forms"
                                          style={pathName === "/forms" ? {color: "red"} : {color: "black"}}>
                                        forms
                                    </Link>
                                </li>


                                <li>
                                    <a role="menuitem" href="https://online.longhorn-associates.com/" target="_blank">
                                        login
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <div id={"RatesAndYieldsContainer"} style={{display:"flex",flexDirection:"row-reverse"}}>
                <div id={"RatesAndYieldsText"} style={{width:"20%"}}><b>Longhorn Unit Trust Prices/Yields ttm:</b></div>
                <div style={{width:"80%", paddingLeft:10}}>
                    {yields &&
                        <Marquee  ref={marqueeRef}  pauseOnClick={true} delay={3}>
                        <p id={"RatesAndYields"} style={{order: 2, display: "flex", flexDirection: "row"}}>
                            <nobr>
                                {yields}
                            </nobr>
                        </p>
                    </Marquee>}


                </div>
            </div>
        </div>
    );
}

