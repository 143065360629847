import React, {useState} from "react"
import "./style.css"
import backgroundImage from "../../assets/images/background/careersHeaderImage.jpg";
import {useForm} from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import ReactLoading from "react-loading";


const styles = {
    main:{
        padding:0,
        margin:0
    },
    subText: {
        color: "#000080",
        fontSize: "20px",
        fontWeight: 600,
    }
}

export default () => {
    const {register, handleSubmit, watch, formState: {errors},reset} = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal)
    const onSubmit = data => {
        setLoading(true);
        MySwal.fire({
            title: 'Sending email',
            color:'rgb(254, 45, 45)',
            allowOutsideClick: false,
            html: (
                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection:"column"
                }}>
                    <h4>Please wait..</h4>
                    <ReactLoading type={'bubbles'} color='rgb(254, 45, 45)' height={'20%'} width={'20%'} />
                </div>
            ),
            showConfirmButton: false,
        });
        axios.post('https://data.mongodb-api.com/app/application-0-mbwce/endpoint/email/careers', data)
            .then(response => {
                MySwal.fire({
                    icon: 'success',
                    title: 'Email sent successfully!',
                    text: 'Thank you for your message.',
                });
                reset()
                setLoading(false);
            })
            .catch(error => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong. Please try again later.',
                });
                setLoading(false);
            });
        setFormSubmitted(true);
    };
    return (
        <main className="row section--navy section--dark" id="row-1" id="main" role="main"
              style={{
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center"
              }}>
            <div style={{height: 200}} className="row section--navy section--dark" id="row-1">
                <section
                    style={{
                        backgroundColor: 'rgba(10,19,35,.15)',
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundPosition: 'center ',
                        backgroundRepeat: 'no-repeat',
                        backgroundBlendMode: 'darken',
                        paddingTop: '72px',
                        paddingBottom: 0,
                        marginBottom: '20px',
                        marginTop: 0,
                        borderWidth: '0 0 0 0',
                        borderColor: '#eae9e9',
                        borderStyle: 'solid',
                        WebkitBackgroundSize: 'cover',
                        MozBackgroundSize: 'cover',
                        OBackgroundSize: 'cover',
                        backgroundSize: 'cover',
                    }}
                    id="c-258500" role="banner" className="hero  hero--dark hero--with-image">
                    <div className="grid hero__grid" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        textAlign: "left",
                        width: "100%"
                    }}>
                        <div className="fusion-column-wrapper fusion-flex-column-wrapper-legacy"
                             style={{
                                 backgroundPosition: 'left top',
                                 backgroundBlendMode: 'overlay',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundSize: 'cover',
                                 minHeight: '0px',
                                 display:"flex",
                                 flexDirection:"column",
                                 alignItems:"center",
                                 textAlign:"left",
                                 padding:30,
                             }}
                        >
                            <h1 className="h0 hero__heading">
                                <span  className="u-nowrap-sm">Careers</span>
                            </h1>
                            <h4 style={{...styles.subText,fontSize:20,color:"#fff",paddingLeft:30,paddingRight:30}}>
                                Our philosophy is to ensure the provision of equal employment opportunities to all. We
                                are always keen to recruit and retain those that are willing to be part of a team that
                                believes in transparency, honest exchange of views and pursuing innovative ways to reach
                                challenging and ambitious goals. We also offer internship programs as part of our agenda
                                to drive skills development.<br/>
                                <b>
                                    careers@longhorn-associates.com
                                </b>
                            </h4>
                        </div>
                    </div>
                </section>
            </div>

            <div id="contact" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={handleSubmit(onSubmit)} className="main_form"
                                  style={{backgroundColor: "#fff", width: "65%", marginLeft: "auto", marginRight: "auto"}}>

                                <div className="row">

                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="Name"
                                            placeholder={`${errors.Name ? "This name is required" : "name"}`}
                                            className={`${errors.Name ? "invalid_input" : "form_contril"}`}
                                            {...register("Name", {required: true})}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <input
                                            type="number"
                                            name="phoneNumber"
                                            placeholder={`${errors.phoneNumber ? "This phone number is required" : "phoneNumber"}`}
                                            className={`${errors.phoneNumber ? "invalid_input" : "form_contril"}`}
                                            {...register("phoneNumber", {required: true})}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={`${errors.email ? "This email is required" : "email"}`}
                                            className={`${errors.email ? "invalid_input" : "form_contril"}`}
                                            {...register("email", {required: true,pattern: /^\S+@\S+$/i})}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder={`${errors.subject ? "This subject is required" : "subject"}`}
                                            className={`${errors.subject ? "invalid_input" : "form_contril"}`}
                                            {...register("subject", {required: true})}
                                        />

                                    </div>

                                    <div className="col-md-12">
                                    <textarea
                                        name="message"
                                        defaultValue={""}
                                        placeholder={`${errors.message ? "This message is required" : "message"}`}
                                        className={`${errors.message ? "invalid_input" : "form_contril"}`}
                                        {...register("message", {required: true})}
                                    />
                                    </div>

                                    <div className="col-sm-12">
                                        {loading ? (
                                            <button disabled={true} className="send_btn" >Sending</button>
                                        ) : (
                                            <button className="send_btn" type="submit">Send</button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
