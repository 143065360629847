import React from 'react'
import {Link} from "react-router-dom";
import "./styles.css"
import locationIcon from '../../../assets/icons/ic-location.png'

export default () => {

    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-cta pt-5 pb-5">
                    <div className="row" style={{justifyContent:"center"}}>
                        <h2 style={{color:"white",padding:10}}>Get in Touch</h2>
                    </div>
                </div>
                <div className="footer-content pt-5 pb-5" style={{paddingTop:"1rem",paddingBottom:"1rem"}}>
                    <div className="row">
                        <div className="col-xl-3 col-md-3 mb-30">
                            <div className="single-cta">
                                <div className="cta-text" style={
                                    {
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        justifyItems:"center",
                                        alignContent:"center",
                                        alignItems:"center",
                                        textAlign:"center",
                                        maxWidth:"15rem"
                                    }}>

                                        <img style={{height:50}} src={locationIcon}/>
                                        <h4>Kitwe Branch</h4>

                                    <span>
                                        Unit E Second floor, Building 2,
                                        <br/>ECL Business Park Stand 7732 Freedom Avenue,
                                        <br/>P.O. Box 50655, Ridgeway, Lusaka,
                                        <br/>Tel: +260 950 85 36 41
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 mb-30">
                            <div className="single-cta">
                                <div className="cta-text" style={
                                    {
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        justifyItems:"center",
                                        alignContent:"center",
                                        alignItems:"center",
                                        textAlign:"center",
                                        maxWidth:"15rem"
                                    }}>
                                    <img style={{height:50}} src={locationIcon}/>
                                    <h4>Solwezi Branch</h4>
                                    <span>
                                         Plot No. 133 Independence Avenue, First floor,
                                        <br/>New Jaids Complex, Solwezi,
                                        <br/>Tel: +260 95 337 8634
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 mb-30">
                            <div className="single-cta">
                                <div className="cta-text" style={
                                    {
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        justifyItems:"center",
                                        alignContent:"center",
                                        alignItems:"center",
                                        textAlign:"center",
                                        maxWidth:"15rem"
                                    }}>
                                    <a href={"https://goo.gl/maps/FNix7GewadiSnp159"} target="_blank" rel="noopener noreferrer">
                                    <img style={{height:50}} src={locationIcon}/>
                                    <h4>Head office </h4>
                                    </a>
                                    <span>
                                            Ground Floor Office Park,
                                            <br/>Plot 1146 / 15,
                                            <br/>Lagos Road, Rhodespark
                                            <br/>P.O. Box 50655, Ridgeway, Lusaka,Zambia
                                            <br/>Tel : +260 211 25 25 40
                                        </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3 mb-30">
                            <div className="single-cta">
                                <div className="cta-text" style={
                                    {
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        justifyItems:"center",
                                        alignContent:"center",
                                        alignItems:"center",
                                        textAlign:"center",
                                        maxWidth:"15rem",
                                        marginRight:"auto",
                                        marginLeft:"auto"
                                    }}>
                                    <img style={{height:50}} src={locationIcon}/>
                                    <h4>Ndola Branch </h4>
                                    <span>
                                            Mwasumina Road,
                                            <br/>Plot 32 Itawa
                                            <br/>P.O. Box 50655, Ridgeway, Lusaka,Zambia
                                            <br/>Tel : +260 956 55 22 38
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                            <div className="copyright-text">
                                <p>© Copyright {new Date().getFullYear()} | Longhorn Associates Limited | All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                            <div className="footer-menu">
                                <ul>
                                    <li><Link to=".">Home</Link></li>
                                    <li><Link to="/services">Services and products</Link></li>
                                    <li><Link to="/team">Our People</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
