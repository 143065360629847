import ChandaChileshe from "../../assets/images/team/ChandaChileshe.jpg";
import BanjiMoono from "../../assets/images/team/BanjiMoono.jpeg";
import DionysiusMakunka from "../../assets/images/team/DionysiusMakunka.jpg";
import NamucanaMusiwa from "../../assets/images/team/NamucanaMusiwa.jpg";
import MarlonNsofu from "../../assets/images/team/MarlonNsofu.jpg";
import IzukanjiMwanza from "../../assets/images/team/IzukanjiMwanza.jpg";
import lewis from "../../assets/images/team/lewis.jpg";

export default [
    {
        id: 1,
        type: "BoardMember",
        image: ChandaChileshe,
        name: 'Chanda HJ Chileshe',
        position: 'Chairman',
        Details: 'Chanda is a seasoned lawyer and Managing partner of Lloyd Jones &amp; Collins with over 35 years experience in\n' +
            'commercial and legal practice both locally and internationally. He holds a Bachelor of Arts – Joint Hons. in Law\n' +
            '&amp; Economics from the University of Keele as well as a Master of Laws Degree, LLM: Taxation, Insurance,\n' +
            'Company Law from the University of London. He is a member of various professional bodies and has sat on\n' +
            'various Boards including Finance Bank (Atlas Mara), Colgate Palmolive and the Revenue Appeals Tribunal,\n' +
            'among others. He has also been involved in major transactions and activities of national interest.',

    },
    {
        id: 2,
        type: "BoardMember",
        image: BanjiMoono,
        name: 'Banji Gideon Moono',
        position: 'CFA – Director',
        Details: '' +
            'Banji is a qualified and experienced Finance, Accounting and Investment professional with extensive experience in Banking and Investments. ' +
            'He has served in senior management positions with various commercial banks including Finance Bank and most recently, the United Bank for Africa where he is currently serving as the institution’s Chief Finance Officer.\n' +
            'Banji is a qualified Chartered Management Accountant (CIMA) and holder of the prestigious CFA Charter. He also holds a Diploma in Treasury Management and is also a fellow of the Zambia Institute of Chartered Accountants (ZICA).'
    },
    {
        id: 3,
        type: "BoardMember",
        image: DionysiusMakunka,
        name: 'Dionysius Makunka',
        position: 'Chief Executive Officer',
        Details: "Dionysius is a qualified and experienced Economics and Finance professional with over twenty (20) years of practice with various institutions. He spent about twenty (20) years at the Bank of Zambia where he served in senior management positions prior to going into private practice. He has also been involved in lecturing at the University of Zambia (Derivatives), ZIBFS (Investment Analysis & Portfolio Management) and the University of Lusaka (Risk Management). He has been and still is a member of various Boards including Kwacha Pension Trust Fund until 2011, Innovative Capital Solutions, Zambia National Building Society, among others.\n" +
            "\n" +
            "Dionysius is a Chartered Accountant (ACCA) and holds the prestigious CFA Charter. He also holds the Bachelor of Accountancy degree from the Copperbelt University as well as a Master of Science in Finance & Economics from Manchester University, UK. He has undertaken several short courses in Economics & Finance globally over the years."
    }, {
        id: 4,
        type: "BoardMember",
        image: NamucanaMusiwa,
        name: 'Namucana Musiwa',
        position: 'Director',
        Details: "Namucana is an entrepreneur with extensive experience in governance and talent acquisition. She is the founder and CEO of Career Prospects Limited, one of the leading recruitment agencies in Zambia.\n" +
            "She has served and continues to serve on various Boards including the Zambia Qualification Authority, Zambia Institute of Human Resources Management, Professional Insurance Corporation and the University of Zambia Council, Bank of Zambia REMCO, Zambia National Building Society REMCO, among others. Namucana has also been involved in the selection of top officers of large private and public institutions. She has received recognition and awards by International institutions for her excellence in governance and Human Resources practice.\n" +
            "Namucana holds a Bachelor of Arts in Public Administration and Economics obtained from the University of Zambia."
    },
    {
        id: 5,
        type:"management",
        image: DionysiusMakunka,
        name: 'Dionysius Makunka',
        position: 'Chief Executive Officer',
        Details: "Dionysius is a qualified and experienced Economics and Finance professional with over twenty (20) years of practice with various institutions. He spent about twenty (20) years at the Bank of Zambia where he served in senior management positions prior to going into private practice. He has also been involved in lecturing at the University of Zambia (Derivatives), ZIBFS (Investment Analysis & Portfolio Management) and the University of Lusaka (Risk Management). He has been and still is a member of various Boards including Kwacha Pension Trust Fund until 2011, Innovative Capital Solutions, Zambia National Building Society, among others.\n" +
            "\n" +
            "Dionysius is a Chartered Accountant (ACCA) and holds the prestigious CFA Charter. He also holds the Bachelor of Accountancy degree from the Copperbelt University as well as a Master of Science in Finance & Economics from Manchester University, UK. He has undertaken several short courses in Economics & Finance globally over the years."
    },
    {
        id: 6,
        type:"management",
        image: MarlonNsofu,
        name: 'Marlon Nsofu',
        position: 'Chief Systems & Data Analytics Officer',
        Details: "Marlon is an investment professional with more than twelve years of experience in the management of pension funds and collective investment schemes, with investing operations encompassing money markets, capital markets, and other economic sectors. His experience also extends to corporate finance and risk management. \n" +
            "\n" +
            "Marlon Nsofu has acquired numerous certifications in the fields of computer science and data science over the course of his career, with the primary objective of solidifying the tools for the application of quantitative finance and financial engineering, further propelled by machine learning, deep learning, and predictive algorithms used for global economic mapping and business intelligence. \n" +
            "\n" +
            "Marlon obtained his bachelor's degree in finance from the Robert H. Smith School of Business at the University of Maryland in the United States of America."
    },
    {
        id: 7,
        type:"management",
        image: IzukanjiMwanza,
        name: 'Izukanji Nachiza Mwanza',
        position: 'ACCA — Chief Finance Officer',
        Details:'Izukanji started her accounting career with AMO Chartered Accountants in 2011 were she worked as a\n' +
            'Management Trainee, the position she held for one year. She later worked at various institutions in the\n' +
            'Finance and Accounting role.\n' +
            'Prior to her accounting career, she pursued a diploma in Chemical Engineering at the Copperbelt University.\n' +
            'Upon completion of her engineering course, she was employed with ZCCM Acid Plant for 6 months and later\n' +
            'moved to Evelyn Hone College for 3 years as a lecturer in the Applied Sciences department.\n' +
            'Izukanji is a Chartered Accountant and holder of the ACCA qualification. She is also a member of both ACCA\n' +
            'and ZICA.'
    },
    {
        id: 8,
        type:"management",
        image: lewis,
        name: 'Lewis Mwale',
        position: 'Chief Sales & Marketing Officer',
        Details: "Lewis is a qualified Social Security Expert and Financial Advisor with over 7 years work experience in the Pensions Industry in Zambia. He holds a Bachelor’s Degree in Business Administration from the Copperbelt University and currently pursuing a Master of Business Adminstration (MBA) - Finance. In addition, he has a Certificate in Pensions Administration and Management. \n" +
            "\n" +
            "Prior to joining Longhorn, Lewis worked as a Financial Controller for Innscor Zambia Limited, later as a Credit and Debt Analyst for Vision Fund Zambia before joining Mukuba Pension Trust in 2016 as a Regional Pensions Officer, a position he held prior to joining Longhorn."
    },

]


